body {
  font-family: "Open Sans", sans-serif;
  margin: 0px;
}

.jumbotron {
  margin: 0;
  margin-bottom: 0 !important;
  padding: 30px;
  height: 800px;
  background-image: linear-gradient(to right, #0a5a83, #0f6897, #0a5a83);
  color: white;
  text-align: center;
  font-family: Apple Chancery;
  font-size: 40px;

  letter-spacing: 0.2em;
}

.name {
  margin-top: 380px;
  font-weight: 500;
  font-size: 2rem;
}
.navbar-dark {
  /* margin-top: -16px; */
  background-color: #0a5a83;
  color: white;
}

#main-nav li {
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 0.25em;
  padding: 0 5px;
}
@media only screen and (max-width: 575px) {
  #header-section #header-image-container img {
    max-width: 80%;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
#about-section {
  background-color: #0a5a83;
  padding-bottom: 40px;
}
#about-section #about-content-div .pull-quote {
  font-family: "Raleway", sans-serif;
  margin: 5px 0 0;
  font-weight: 800;
  color: #6bb8d9;
  margin-bottom: 50px;
}

.heading-div {
  text-transform: uppercase;
  letter-spacing: 10px;
  color: #cdeeff;
}
p {
  padding: 1px;
  color: white;
}
.h3 {
  color: #4588a5;
}
#about-section #about-content-div #headshot-div img {
  max-width: 50%;
  border-radius: 10px;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.4666666666666667);
}
@media only screen and (max-width: 768px) {
  #about-section #about-content-div #headshot-div img {
    max-width: 40%;
  }
}
#about-section #stack-div {
  background-color: #1b7daf;
  border: 5px solid #1f5f82;
  border-radius: 50px;
  top: 100px;
}
#about-section #stack-div #stack-icon-div {
  max-height: 25vh;
  padding-bottom: 30px;
}
#about-section #stack-div #stack-icon-div .stack-icon {
  max-height: 80px;
}

#projects-section {
  background-color: #1f5f82;
  padding: 100px 0 30px;
}

.heading-div {
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 10px;
  color: #cdeeff;
  margin: 0;
  font-size: 50px;
  -webkit-appearance: none;
  appearance: none;
  padding-top: 31px;
}
#projects-section #call-to-action-div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #4588a5;
  border: 5px solid #124663;
  border-radius: 50px;
  top: 100px;
}
.heading-div {
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 10px;
  color: #cdeeff;
  margin: 0;
  font-size: 50px;
  -webkit-appearance: none;
  appearance: none;
  padding-top: 31px;
}
#projects-section #call-to-action-div .body-copy {
  margin: 0 auto;
  text-align: center;
  color: #cdeeff;
  max-width: 80%;
  padding: 0 0 30px;
}
#contact-section {
  background-color: #124663;
  padding: 100px 0 50px;
}
.heading-div {
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 10px;
  color: #cdeeff;
  margin: 0;
  font-size: 50px;
  -webkit-appearance: none;
  appearance: none;
  padding-top: 31px;
}
#contact-section #form-div {
  margin: 30px 0 0;
}
#contact-section #form-div form {
  background-color: #1f5f82;
  border-radius: 5px;
  padding: 10px;
  margin: 0 auto;
  max-width: 700px;
}
#contact-section #form-div form input[type="text"],
#contact-section #form-div form textarea {
  font-family: "Raleway", sans-serif;
  color: #cdeeff;
  background-color: #124663;
  padding: 12px 20px;
  margin: 8px 0;
  border: 1px #cdeeff;
}
#contact-section #form-div form #submit-bttn-div #submit {
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  color: #cdeeff;
  transition-duration: 0.3s;
  box-shadow: 0 4px #124663;
  padding: 5px;
  width: 100%;
  border: 1px solid #cdeeff;
  background-color: transparent;
}
#contact-section #form-div form #submit-bttn-div {
  margin: 0 auto;
}
#contact-section #contact-menu-div {
  padding: 50px 0 0;
}
#contact-section #contact-menu-div .social-media-icon {
  max-height: 80px;
}

/* #carouselButton {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 100;
} */
.carousel-inner {
  height: 600px;
  width: 70%;
}
.carousel-item {
  padding: 60px;
  height: 100%;
}
.carousel-item img {
  height: 100%;
}
/* #homeCarousel .carousel-item img {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
} */
.projectCarousel {
  display: flex;
}
footer {
  min-height: 25vh;
  align-items: center;
  background-color: #084668;
  padding-top: 30px;
}
/* .branding {
    max-width: 90%;
  }*/

#footer-container {
  padding: 0 0 50px 0;
}

.footer-heading {
  font-weight: 400;
  color: #cdeeff;
  margin-top: 0px;
  padding-top: 30px;
}

#footer-menu {
  list-style: none;
}
.my-nav-link {
  text-transform: uppercase;
  font-size: 0.8em;
  letter-spacing: 0.25em;
  text-decoration: none;
  color: #cdeeff;
  transition: 300ms;
}
.my-nav-link:hover {
  color: darkblue;
}
#projects-section {
  background-color: #1f5f82;
  padding: 100px 0 30px;
}
#projects-section #projects-grid {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
  padding: 10px;
  margin-top: 30px;
}
@media only screen and (min-width: 1200px) {
  #projects-section .project-thumb {
    min-width: 500px;
    max-width: 650px;
    min-height: 350px;
    max-height: 500px;
  }
}
#projects-section .project-thumb {
  border-radius: 5px;
  min-width: 300px;
  max-width: 500px;
  min-height: 200px;
  max-height: 350px;
  -webkit-flex: 2 1;
  flex: 2 1;
  margin: 10px;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.4666666666666667);
  transition: 0.5s;
  background-size: cover;
}
@media only screen and (min-width: 992px) {
  #projects-section .project-thumb {
    min-width: 350px;
    max-width: 629px;
    min-height: 350px;
    max-height: 500px;
  }
}
/* @media only screen and (min-width: 768px) {
  #projects-section .project-thumb {
    min-width: 300px;
    max-width: 350px;
    min-height: 200px;
    max-height: 350px;
  }
} */

.navbar-dark .navbar-toggler {
  color: hsla(0, 0%, 100%, 0.55);
  border-color: hsla(0, 0%, 100%, 0.1);
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
.navbar-dark .navbar-toggler {
  color: hsla(0, 0%, 100%, 0.55);
  border-color: hsla(0, 0%, 100%, 0.1);
}
.modal-content img,
.modal-content video {
  max-width: 100%;
  border-radius: 5px;
}
video {
  object-fit: contain;
}
.project-description {
  color: #47a6d7;
  margin-top: 10px;
}
p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.modal-content a.code-bttn {
  text-decoration: none;
  background-color: #cdeeff;
  border: 1px solid #1f5f82;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Raleway", sans-serif;
  color: #1f5f82;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  padding: 10px 15px;
  transition-duration: 0.5s;
  margin: 0 10px 0 0;
}
